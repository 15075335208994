/* eslint-disable */

const Policy = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-content">
                            <div className="most-popular">
                                <div className="row">
                                    <div className="col-lg-12" >
                                        <div className="heading-section">

                                            <h4 key={'header'}><em>Privacy Policy</em></h4>
                                            <div style={{color:'white'}}>
                             
If you require any more information or have any questions about our privacy policy, please feel free to contact us by email at 774sachin@gmail.com.

At www.lovefeverstatus.com, the privacy of our visitors is of extreme importance to us. This privacy policy document outlines the types of personal information is received and collected by www.lovefverstatus.com and how it is used.
<br/><br/>
<h5>Personally Provided Information</h5>
You do not have to give us personal information to visit www.lovefeverstatus.com. If you choose to provide us with additional information through an e-mail message, inquiry form, comments, etc., we maintain the information as long as needed to respond to your question, inquiry, or to fulfill the stated purpose of the communication as well as archive them for some statistical reasons. Except for authorized law enforcement investigations, we do not give, share, sell or transfer any personal information to a third party.

<h5>Website Traffic Analysis Software</h5>
For site security purposes and to ensure that this service remains available to all users, we employ software programs to monitor traffic to identify unauthorized attempts to upload or change information, or otherwise cause damage. We also monitor our traffic to analyze visitorss information, such as, but not limited to, operating system, browsers, screen resolutions and similar. In the event of authorized law enforcement investigations, and pursuant to any required legal process, information from these sources may be used to help identify an individual.
<br></br><br></br>
<h5>Group Subscription</h5>
If users wish to subscribe to our group, we ask for contact information. Recipients of our newsletters can unsubscribe using the instructions listed at the end of the e-mail.
<br></br><br></br>
<h5>SMS Subscription / Alerts</h5>
You are asked to provide certain information if you request to receive daily
SMS alerts. We collect this information to keep our SMS broadcast as per the dynamics of our subscribers. Like all other instances, this information will never be given/sold to third party, otherwise asked by the Law of the Land.

<br></br><br></br>
<h5>Third Party Advertising Partners</h5>
All users of  lovefeverstatus must keep this in mind that third parties may be placing and reading cookies on your browsers, or using web beacons to collect information as a result of ad serving on lovefeverstatus.com. For, instance one of our partners, Google uses the DoubleClick DART cookie on lovefeverstatus.com displaying AdSense for content ads. Similarly, other advertising partners may use their respective cookies to track advertising stats.Google Analytic Cookies

This website uses Google Analytics, a web analytics service provided by Google, Inc. (“Google”). Google Analytics uses “cookies”, which are text files placed on your computer to help the website analyse how visitors use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States . Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.

<br></br><br></br>
<h5>External Links</h5>
Our web sites contain links to other sites. lovefeverstatus.com is not responsible for the privacy practices or content of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of each web site to which we may link that may collect personal information.

<br></br><br></br>
<h5>Responsibily</h5>
lovefeverstatus.com’s team cannot be held responsible for any activity resulting any kind of loss to an individual or group.

Note: This Page can get updated any time, without an prior notice, so keep checking back.
                                                <br/>
                                                <br/> <br/>
                                               
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Policy;
