/* eslint-disable */
import { useEffect, useState } from "react";
import storage from "../../firebase";
import axios from "axios";
import fileDownload from "js-file-download";
import Spinner from "../../component/spinner/spinner";
import { database } from "../../firebase";
import Select from 'react-select';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

interface Files {
    id: string,
    name: string,
    url: string
}

interface Catagorys {
    catagoryId: number,
    catagoryName: string
}

const Upload = () => {

    const [data, setData] = useState<Files[]>([]);
    const [loading, setLoading] = useState(true);
    const [catagorys, setCatagorys] = useState([])
   // const [file, setFile] = useState<File | null>(null);
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const [uploading, setUploading] = useState(false);
    const [uploadedUrl, setUploadedUrl] = useState('');
    const [selectedOption, setSelectedOption] = useState();
    const [message, setMessage] = useState('');

    useEffect(() => {

        const fetchData = async () => {
            const snapshot = await database.ref().once('value');
            let options = snapshot.val().catagorys.map(function (catagory: Catagorys) {
                return { value: catagory.catagoryId, label: catagory.catagoryName };
            })
            setCatagorys(options)
            setLoading(false);
        };
        fetchData()
    }, []);

    // const handleFileChange = (e: any) => {
    //     const selectedFile = e.target.files[0];
    //     setFile(selectedFile);
    // };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
          setSelectedFiles(e.target.files);
        }
      };
    // const handleUpload = async () => {
    //     try {
    //         if (!file) {
    //             alert('Please select a file!');
    //             return;
    //         }
    //         setUploading(true);
    //         var storageFolder = selectedOption != null ? selectedOption['label'] : 'videos';
    //         const storageRef = storage.ref(storageFolder);

    //         const fileRef = storageRef.child(file.name);
    //         await fileRef.put(file);

    //         const url = await fileRef.getDownloadURL();
    //         var id = selectedOption != null ? selectedOption['value'] : '1';
    //         await insertRecord(url, "", file.name, id.toString())
    //         setFile(null);
    //     } catch (error) {
    //         setMessage('Error uploading file, please try again.');
    //     } finally {
    //         setUploading(false);
    //     }
    // };

    const handleUpload = () => {
        
        if (selectedFiles) {
         setLoading(true);
          const uploadPromises: Promise<Files>[] = [];
    
          for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            uploadPromises.push(uploadFile(file));
          }
    
          
          // Wait for all upload promises to resolve
          Promise.all(uploadPromises)
          .then(async (uploadedFiles) => {
              console.log('All files uploaded successfully');
              // Call a function after all uploads have completed
              // Example: callFunctionAfterUpload();
              for (let i = 0; i < uploadedFiles.length; i++) {
                const { url, name, id } = uploadedFiles[i];
                await insertRecord(url, "", name, id);
              }

              toast.success("Files Uploaded !", {
                position: "top-center"
              });
        
            })
            .catch((error) => {
              console.error('Error uploading files:', error);
              toast.error("Error uploading files:", {
                position: "top-center"
              });
              
            }).finally(()=>{
                setLoading(false);
              
            });
        }
      };

      const uploadFile = (file: File): Promise<Files> => {
        // Perform upload logic for the file
        return new Promise(async (resolve, reject) => {
            var storageFolder = selectedOption != null ? selectedOption['label'] : 'videos';
            const storageRef = storage.ref(storageFolder);

            const fileRef = storageRef.child(file.name);
            await fileRef.put(file);

            const url = await fileRef.getDownloadURL();
            var id = selectedOption != null ? selectedOption['value'] : '1';
            const uploadedFile: Files = {
                id: id.toString(), // You can use any suitable unique identifier for the file
                name: file.name,
                url: url,
            };
            resolve(uploadedFile);
        });
      };

    const insertRecord = async (videurl: string, imageurl: string, videoname: string, id: string) => {

        var newRecordData = {
            "videoname": videoname,
            "url": videurl,
            "imageurl": imageurl
        };
        var newRecordRef = database.ref(id).push();
        newRecordRef.set(newRecordData, function (error) {
            if (error) {
                setMessage("Data could not be saved." + error);
            } else {
                setMessage("Data saved successfully.");
            }
        });
    }
    const handleOptionChange = (selectedOption: any) => {
        setSelectedOption(selectedOption);
    };

    return (
        <>
          <ToastContainer />
            {loading ? <Spinner></Spinner> :
                <>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="page-content">
                                    <div className="most-popular">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="heading-section">
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                        <div className="file-input-container">
                                                            <input accept="video/*" style={{ color: 'white' }} type="file" onChange={handleFileChange} multiple  />
                                                            {/* {file && <p style={{ color: 'white' }} className="file-name">{file.name}</p>} */}
                                                        </div>
                                                        <Select
                                                            value={selectedOption}
                                                            onChange={handleOptionChange}
                                                            options={catagorys}
                                                            styles={{ control: (provided) => ({ ...provided, width: '200px' }) }}
                                                        />
                                                        <button onClick={handleUpload} disabled={!selectedFiles || !selectedOption || uploading}>
                                                            Upload File
                                                        </button>
                                                        {uploading && <p>Uploading...</p>}
                                                        {uploadedUrl && (
                                                            <div>
                                                                <p>File Uploaded Successfully! {message}</p>
                                                            </div>
                                                        )}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </>}
        </>
    );
};

export default Upload;
