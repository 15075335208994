/* eslint-disable */

const ContentPolicy = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-content">
                            <div className="most-popular">
                                <div className="row">
                                    <div className="col-lg-12" >
                                        <div className="heading-section">

                                            <h4 key={'header'}><em>Content Policy</em></h4>
                                            <div style={{color:'white'}}>
                                            We actively remove content which is not allowed on our Platform and violates both our Guidelines as well as applicable Indian laws. 
                                            If such content comes to our attention, we may take it down or ban user accounts. 
                                            If you come across any content that violates these Guidelines, we encourage you to report it. 
                                            The intent of the creator is important. We understand the importance of creative freedom, however we do not welcome content that intends to bring discomfort, spread what may be considered hate speech and abuse or promote violence and illegal activities.
<br/><br/>
<h6>a.Adherence to Applicable Laws</h6>
<br></br>

All content, including without limitation, content that is uploaded, posted, commented on, or shared by you on our Platform, must adhere to the laws of India, including without limitation, the Indian Penal Code, 1860 and, the Information Technology Act, 2000 along with all rules and amendments made under such laws. We cooperate with legal authorities and follow enforcement mechanisms in cases of breach of applicable laws.

<br/><br/>
<h6>b. Nudity and Pornography</h6>
<br></br>

We allow content that may have limited sexual imagery, provided it is posted for artistic and educational purposes, public awareness, humour or satirical purposes. Content which contains the following is prohibited on the Platform and will be considered as a strict violation of these Guidelines:
<br></br><br></br>
i.    sexually explicit, pornographic or nude material or images/videos that expose private parts (sexual organs, female breasts and nipples, buttocks) and/or portray sexual activities;
<br></br><br></br>
ii.    videos or images of people in compromising positions or content which portrays sexual actions or fetish or erotic intent or sexual arousal;
<br></br><br></br>
iii.   sextortion or revenge pornography;
<br></br><br></br>
iv.   Bestiality or zoophilia;
<br></br><br></br>
v.   content that exploits or endangers any person (for example, listing of phone numbers, or other personal information aimed at any exploitation or endangerment of a person including for the purposes of encouraging or soliciting prostitution or escort services);
<br></br><br></br>
vi. child pornography (including without limitation, creation, promotion, glorification, transmission or browsing of child pornography); or
<br></br><br></br>
vii.  content on rape, sexual objectification, non-consensual activities  and molestation.
<br></br><br></br>
<h6>c.     Harassment or Bullying</h6>
<br></br>
We strongly condemn any kind of harassment or bullying on our Platform. We intend to give our users the freedom to express themselves without the fear of emotional or psychological distress. We urge you to ignore any content that you may find petty and annoying. In addition to this, we also encourage you to report any such content that harasses another person or intends to degrade or shame any individual.

Content which qualifies as a violation of these Guidelines includes, but is not limited to:

<br></br>
i. Posting abusive language or curse words, morphed images, and/or malicious recordings.
<br></br>

ii. Objectifying someone based on their race, caste, colour, disabilities, religion, sexual preferences and /or making sexual advances or otherwise engaging in sexual misconduct will not be tolerated on this Platform. Similarly, extorting or blackmailing any individual otherwise or on the basis of the abovementioned content is strictly prohibited.
<br></br>
iii. If someone blocks you from their account, kindly do not try and contact them from a different account. If a user does not want to engage with you on the Platform, we urge you to respect the same and vice versa.
<br></br>
iv.        any image or information of a person that is shared without their consent with an intent to harass, distress or endanger them.

However, if a matter involves critical discussion and deliberation of such individuals who are featured in the news or tend to have a large public audience, we may allow it subject to the Terms and these Guidelines.

<br></br><br/>
<h6>d.Intellectual Property</h6>
<br></br>
We aim to safeguard intellectual property rights and consider violations of such rights as serious misconduct. All content such as literary, musical, dramatic, artistic, sound recordings, cinematographic works is subject to intellectual property protection.

Posting  content on the Platform which is not original and is copied from an individual/organisation who owns the intellectual property rights in such content/ works is not allowed. Any content which violates the intellectual property rights of third parties will be taken down and strict action will be taken against users who are repeat defaulters. If you wish to reshare such content from within the Platform, kindly do not remove any attributions, watermarks and original captions which state the authentic source of the content. In addition to this, please take the required permissions and give due credits to fellow users or any other organisation/individual who own the intellectual property rights in such content by mentioning their name and /or the original source.
<br></br><br></br>
<h6>e.      Violence</h6>

<br/>
Violence includes all content that causes discomfort to our users due to the goriness in the content, such as but not limited to graphical images or videos that glorify violence and suffering, or intends to incite violence, depiction of physical violence or animal cruelty. Content which promotes dangerous and illegal activities, or praises individuals, groups or leaders involved in terrorism, organized violence or criminal activities is strictly prohibited.

 

Educative or informative content pertaining to violence may be allowed on the Platform. Violent content on the Platform in the form of fictional set-up, martial arts may be permitted subject to these Guidelines.

<br></br><br></br>
<h6>f.     Hate Speech and Propaganda</h6>

<br/>
Content that promotes violent behaviour against an individual or a group of individuals, intends to intimidate, target or demean any particular religion, race, caste, ethnicity, community, nationality, disability (physical or mental), diseases or gender, is prohibited. Any kind of content which produces hatred or has the intention of creating or spreading hatred or hate propaganda along the lines of including, but not limited to religion, caste, ethnicity, community, sexual orientation, or gender identity is also not allowed. We do not entertain content that spreads discrimination, intends to justify violence based on the above-mentioned attributes and refers to an individual or a group of individuals as inferior in any sense or with negative connotations.

We urge you to refrain from incendiary commentary and publishing theories or hateful ideologies that may cause outrage to our users and influence them negatively. We may permit such content which intends to raise awareness about these issues or challenge it, subject to clear intention of posting such content on the Platform.

<br></br><br></br>
<h6>g.     Abuse, Self-Injury or Suicide</h6>
<br/>
We do not permit content that exhibits suicide or such tendencies, triggers self-injury and harm or encourages participation in dangerous activities. Posting any content that relates to physical, mental, sexual, or psychological maltreatment, neglect or abuse of any person, be it that of a child or an adult, is strictly condemned. Content showcasing self-harm, glorifying self injury or suicide or even stating instructions on how to commit self-harm through any means is not allowed. Further, content that identifies, tags, attacks and negatively targets or makes fun of  victims and survivors of psychological/physical maltreatment, abuse, self-injury or domestic abuse or any other form of violence is prohibited.

We allow content that intends to provide support, aid and relief to those undergoing such serious issues. We also allow users to share their experiences which may provide coping mechanisms for those who need help, subject to the intent of posting such content.


<br></br><br></br>
<h6>h.      Illegal Activities</h6>
<br/>
We have zero-tolerance for content that advocates or promotes illegal activities. We prohibit content related to organized crime, criminal activities, promotion/sale/use of weapons, firearms and explosives, violence or terrorist activities. Sale of illegal goods or services, regulated goods, drugs and controlled substances, and soliciting or selling of sexual services is strictly forbidden.

Users are not allowed to post content that displays tutorials or instructions or educates the users about illegal and prohibited activities including, but not limited to participating in criminal activities, making bombs or encouraging or doing or trading in drugs. Do not use our Platform to solicit or facilitate any transaction or gift involving such goods and services which are declared illegal by the Government of India


Impersonating another person (such as your family, friends, celebrities, brands or any other individuals/organisations) and distributing false or misleading information on our Platform in order to make personal or financial gain will be considered fraud.

<br/><br/>
<h6>i.      Non-Consensual (Personal) Content</h6>
<br/>
Posting or misusing personal content or data or information of another person, including pictures or videos of other people who have not given express consent to such material being posted, is not allowed. Do not post anyone’s personal or intimate photos or videos without their permission or consent. We will remove such content.

Revealing someone’s personal data or sensitive personal information, including without limitation: contact information, address, financial information, Aadhar Number, healthcare information, sexual or intimate images and videos, passport information, or threatening someone to reveal or use such information, will be considered as harassment, and such activities are strictly unacceptable.

<br></br><br></br>
<h6>j.     Spam</h6>
<br/><br/>
Content which displays or promotes false advertisements, fraudulent or misleading representations and security breaches, falls under the ambit of commercial spam. This type of content interferes with the smooth functioning of the Platform and deters other users from sharing and connecting. It is important that the content you share is authentic and facilitates the creation of a safe and trusted environment for people to post on the Platform. Do not post the same content multiple times if it intends to annoy viewers or sell goods/services in order to promote commercial spam. Do not use artificial and manipulative means to generate traffic or increase followers, likes, views, comments and shares.

If you want to promote your goods or services, we urge you to do so in an authentic manner.

<br></br><br></br>
<h6>k.      Fake News</h6>
<br/><br/>

We aim to combat the spread of misinformation on our Platform. Any kind of content which spreads deliberate misinformation, disinformation, hoaxes or fake propaganda, with the intent to mislead the users or general public at large, is not permitted. We prohibit posting of content that exaggerates an existing piece of news by introducing non-factual elements into it.

We do not allow content on the Platform that misleads users or attempts to create an avenue  for fabricating content, or attempts to damage someone’s reputation, or hurt their financial or political standing on the basis of incorrect information. We engage third party fact checkers to combat the menace of fake news basis which we proactively warn our users that a piece of content is found to be factually incorrect. We urge you to take the same into consideration and act accordingly.

However, we do not confuse fake news with any satire or parodies. We allow for such content on the Platform provided the content does not mislead other users and the intent behind the same is not to spread false information.                                                <br/>
                                                <br/> <br/>
                                               
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ContentPolicy;
