/* eslint-disable */
import Modal from 'react-modal';
import './video-player.css'
import axios from 'axios';
import fileDownload from 'js-file-download';

const VideoPlayer = ({ isOpen, videoSource, onClose }: any) => {

  const download = (videoSource:any)=> {
    axios.get(videoSource.url, {
      responseType: 'blob',
    }).then(res => {
        fileDownload(res.data, videoSource.videoname)
    });
  }
  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        content: {
          width: '60%',
          height: '60%',
          position: 'relative',
          top: 'auto',
          left: 'auto',
          right: 'auto',
          bottom: 'auto',
          border: 'none',
          background: 'transparent',
          overflow: 'hidden',
          backgroundColor: '#27292a',
          padding: '30px 15px',
          borderRadius: '23px'
        },
      }}
    >
      <div className="main-button" style={{
       
       position: 'absolute', top: 10,
       right: 10,
       zIndex: 1000,
     }}>
     <a  onClick={onClose}>Close</a>
   </div>
      <video controls width="100%" height="100%" autoPlay={true}>
        <source src={videoSource.url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="main-button" style={{
       
          position: 'absolute',
          top: 10, left: 10, zIndex: 1000
        }}>
        <a  href='#' onClick={() =>download(videoSource)}>Download</a>
      </div>
    </Modal>
  );
}

export default VideoPlayer;
