import React, { ReactNode } from 'react';
interface ContentProps {
    children: ReactNode;
}

const Content = ({ children }: ContentProps) => {
    return <div>{children}</div>;
};

export default Content;
