/* eslint-disable */
import { useEffect, useState } from "react";
import { database } from "../../firebase";
import Spinner from "../spinner/spinner";
import { Link, useLocation  } from "react-router-dom";
import { useCategory } from "../../context/CatogryContext";

interface HeaderProps {
    title: string;
}
interface Catagorys {
    catagoryId: number,
    catagoryName: string
}
const Header = ({ title }: HeaderProps) => {
    const location = useLocation();
    const [active, setActive] = useState(false);
    const [catagorys, setCatagorys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const { category, setCategory } = useCategory();


    const { innerWidth: width, innerHeight: height } = window;
    useEffect(() => {
        if (innerWidth > 991) {
            setActive(true);
            setIsMobile(true);
        }

        const handleResize = () => {
            if (innerWidth > 991) {
                setActive(true);
                setIsMobile(true);
            }
            else{
                setIsMobile(false)
            }
        }
        window.addEventListener('resize', handleResize);
    }, []);
    useEffect(() => {

        const fetchData = async () => {
            const snapshot = await database.ref().once('value');
            let options = snapshot.val().catagorys;
            setCatagorys(options)
            setLoading(false);
        };
        fetchData()



    }, []);

    // const handleSetCategory = (newcategory:any) => {
    //     if (location.pathname !== '/') {
    //        window.location.href = '/';
    //     }
    //     //setCategory(newcategory);
    //     isMobile ? setActive(true) : setActive(false);
    //   };

    return (
        <>
            {loading ? <Spinner></Spinner> : <>
                <header className="header-area header-sticky">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <nav className="main-nav">
                                    <a href="/" className="logo">
                                        <img src="/assets/images/logo.png" alt="" />
                                    </a>
                                    <ul className="nav" style={active ? { display: 'block' } : { display: 'none' }}>
                                        <li><a href="/" >Home</a></li>
                                        {catagorys.slice(0, 5).map((item,) => (
                                                <li>  <a  href={`/home/${item['catagoryName']}/${item['catagoryId']}/whatsapp status video`} >{item['catagoryName']}</a> </li> 
                                        ))}
                                        <li><a href="/policy" style={{ backgroundColor: 'white', color: 'black'}} >Policy</a></li>
                                    </ul>
                                    <a onClick={() => setActive(!active)} className={active ? 'menu-trigger active' : 'menu-trigger'}>
                                        <span>Menu</span>
                                    </a>
                                </nav>
                            </div>
                        </div>
                    </div>
                </header>
            </>
            }
        </>
    );
};

export default Header;


