const Footer = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-content">
                            <div className="main-banner">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="header-text">
                                            <h6>Welcome To </h6>
                                            <h4><em>Lover</em> Fever Status</h4>
                                            <div className="main-button">
                                                <a href="/">Browse Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <div className="container">
                <div className="row" style={{textAlign: 'center'}}>
                <a href="/policy">Policy</a> / <a href="/content-policy">Content Policy</a>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <p>Copyright © {new Date().getFullYear()} <a href="/upload">Amruta Technology </a> Company. All rights reserved.

                                <br />Serving The Love</p>
                        </div>
                    </div>
                </div>
            </footer>


        </>
    );
};

export default Footer;
