import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Layout from './component/layout/layout';
import Home from './pages/home/home'
import Upload from './pages/upload/upload';
import { Suspense, lazy } from 'react';
import { CategoryProvider } from './context/CatogryContext';
import Policy from './pages/policy/policy';
import ContentPolicy from './pages/policy/content-policy';

function App() {
  //   const Home = lazy(() => import('./pages/home/home')); // Assuming Home is in a separate file
  // const Upload = lazy(() => import('./pages/upload/upload')); // Assuming Upload is in a separate file

  return (
   
    <Router>
    <CategoryProvider>
      <Routes>
        <Route path="/" element={<Layout title=""><Home /></Layout>} />
        <Route path="/home/:pCatagory/:pId/whatsapp status video" element={<Layout title=""><Home /></Layout>} />
        {/* <Route path="/video" element={<Layout title=""><Home /></Layout>} /> */}
        <Route path="/upload" element={<Layout title=""><Upload /></Layout>} />
        <Route path="/policy" element={<Layout title=""><Policy /></Layout>} />
        <Route path="/content-policy" element={<Layout title=""><ContentPolicy /></Layout>} />
      </Routes>
    </CategoryProvider>
  </Router>
  

  );
}

export default App;
