import React, { ReactNode } from 'react';
import Header from '../header/header';
import Content from '../content/content';
import Footer from '../footer/footer';

/* eslint-disable */
interface LayoutProps {
    title: string;
    children: ReactNode
}

const Layout: React.FC<LayoutProps> = ({ title, children }) => {
    return (
        <div>
            <Header title={title} />
            <Content>{children}</Content>
            <Footer></Footer>
        </div>
    );
};

export default Layout;
