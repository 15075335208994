/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import Spinner from "../../component/spinner/spinner";
import { useParams } from "react-router-dom";
import VideoPlayer from "../../component/video/video-player";
import { database } from "../../firebase";
import { Category, useCategory } from "../../context/CatogryContext";
import './home.css'
import ReactPaginate from "react-paginate";

interface Files {
    videoname: string,
    url: string,
    id: string
}
const Home = () => {
    const { pCatagory, pId } = useParams<{ pId: string; pCatagory: string }>();

    const [category, setCategory] = useState<Category>({
        catagoryId: parseInt(pId || "0"),
        categoryName: pCatagory || ''
    });

    const [data, setData] = useState<Files[]>([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState<Files>();
    const [loading, setLoading] = useState(true);
    const [catagorys, setCatagorys] = useState([]);
    const divRef = useRef<HTMLDivElement>(null);
    const [perPage, setPerPage] = useState(10); // Number of items per page
    const [pageCount, setPageCount] = useState(0); // Total number of pages
    const [currentPage, setCurrentPage] = useState(0); // Current page

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const snapshot = await database.ref().once('value');
            let allFiles: Files[] = [];
            let options = snapshot.val().catagorys;
            setCatagorys(options);
            if (category) {
                var id = category.catagoryId.toString();
                var videos = snapshot.val()[id];
                if (videos) {
                    var myData = Object.keys(videos).map((key: any) => {
                        return videos[key];
                    })
                    allFiles = allFiles.concat(myData);
                }
            }
            setData(allFiles)
            setLoading(false);
            if (divRef.current) {
                divRef.current.focus();
            }
        };
        if (category.catagoryId !== 0) {
            fetchData();
        }
    }, [category]);


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const snapshot = await database.ref().once('value');
            let options = snapshot.val().catagorys;
            setCatagorys(options);
            let allFiles: Files[] = [];

            snapshot.val().catagorys.map((dbcatagory: any) => {
                var id = dbcatagory.catagoryId.toString();
                var videos = snapshot.val()[id];
                if (videos) {
                    var myData = Object.keys(videos).map((key: any) => {
                        return videos[key];
                    })
                    allFiles = allFiles.concat(myData);
                }

            });

            setData(allFiles)
            setLoading(false);
            if (divRef.current) {
                divRef.current.focus();
            }
        };
        if (category.catagoryId === 0) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        setPageCount(Math.ceil(data.length / perPage));
    }, [data, perPage]);

    // Handle page change
    const handlePageClick = ({ selected }: any) => {
        setCurrentPage(selected);
    };

    // Calculate the offset for data
    const offset = currentPage * perPage;
    const openModal = (videoSource: any) => {
        setSelectedVideo(videoSource);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
       // setSelectedVideo({videoname:'',url:'', id:"0"});
    };
    const download = (videoSource:any)=> {
        axios.get(videoSource.url, {
          responseType: 'blob',
        }).then(res => {
            fileDownload(res.data, videoSource.videoname)
        });
      }
      
    return (
        <>
            {selectedVideo && <VideoPlayer isOpen={modalIsOpen} videoSource={selectedVideo} onClose={closeModal} />}
            {loading ? <Spinner></Spinner> :
                <>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="page-content">
                                    <div className="main-banner">
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="header-text">
                                                    <h6>Welcome To </h6>
                                                    <h4><em>Lover</em> Fever Status</h4>
                                                    <div className="main-button">
                                                        <a href="/">Browse Now</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="most-popular">
                                        <div className="row">
                                            <div className="col-lg-12" ref={divRef}>
                                                <div className="heading-section">

                                                    {data.length === 0 ? <h2 key={'nodata'}>No Data</h2> : <h4 key={'header'}><em>Most Popular</em> Right Now</h4>}
                                                    <fieldset>
                                                        <div className="chip-group" role="radiogroup">
                                                            {catagorys.map((item: any) => (
                                                                <div onClick={() => setCategory(item)} className={category?.catagoryId == item.catagoryId ? "chip chip-checkbox" : "chip"} aria-labelledby="radioOneLabel" role="radio" aria-checked="false">
                                                                    <span id="radioOneLabel">{item['catagoryName']}</span>
                                                                </div>
                                                            ))}

                                                        </div>
                                                    </fieldset>
                                                </div>
                                                <div className="row">

                                                    {data.slice(offset, offset + perPage).map((item, index) => (
                                                        <div className="col-lg-3 col-sm-6" key={index + 'div'}>
                                                            <div className="item">
                                                                <video

                                                                    onClick={() => openModal(item)}
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '320px',
                                                                        objectFit: 'cover',
                                                                    }}
                                                                    key={index} src={item.url} />

                                                                <h4 style={{ width: '80%' }}>{item.videoname}<br /></h4>
                                                                <ul>
                                                                    <li ><a href='#' onClick={() =>download(item)} ><i style={{ fontSize: 'xx-large' }} className="fa fa-download"></i></a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>


                                                <ReactPaginate
                                                    pageCount={pageCount}
                                                    pageRangeDisplayed={5} // Number of pages displayed in the pagination bar
                                                    marginPagesDisplayed={2} // Number of pages displayed at the beginning and end of the pagination bar
                                                    onPageChange={handlePageClick}
                                                    activeClassName={'active'}
                                                    renderOnZeroPageCount={null}
                                                    containerClassName="pagination justify-content-center"
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </>}
        </>
    );
};

export default Home;
