// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app'; // Import the main Firebase module
import 'firebase/compat/storage';
import 'firebase/compat/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCoZ1W2mpUTOvzrds_4AEJrp75M49Gy-PA",
  authDomain: "love-feverreact-2024.firebaseapp.com",
  projectId: "love-feverreact-2024",
  storageBucket: "love-feverreact-2024.appspot.com",
  messagingSenderId: "666788489169",
  databaseURL: "https://love-feverreact-2024-default-rtdb.firebaseio.com",
  appId: "1:666788489169:web:67b5febad228ae962122f0",
  measurementId: "G-2BZ7ZS7XW4"
};

firebase.initializeApp(firebaseConfig);
var  storage = firebase.storage();
export const database = firebase.database();
// Get a reference to the storage service, which is used to create references in your storage bucket
export default  storage ;