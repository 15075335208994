import React, { createContext, useState, useContext } from 'react';

export interface Category {
  catagoryId: number;
  categoryName: string;
}

interface CategoryContextType {
  category: Category | undefined;
  setCategory: React.Dispatch<React.SetStateAction<Category | undefined>>;
}

const CategoryContext = createContext<CategoryContextType | undefined>(undefined);

export const CategoryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [category, setCategory] = useState<Category | undefined>(undefined);

  return (
    <CategoryContext.Provider value={{ category, setCategory }}>
      {children}
    </CategoryContext.Provider>
  );
};

export const useCategory = (): CategoryContextType => {
  const context = useContext(CategoryContext);
  if (context === undefined) {
    throw new Error('useCategory must be used within a CategoryProvider');
  }
  return context;
};
